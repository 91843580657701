/* eslint-disable no-undef */
import React from "react"
import StructurePageMornese from "@components/pageCasosdeExito/template/templatePageMornese"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Mornese [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/mornese/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Mira como Mornese, Contact Center con operaciones globales, usa Beex como plataforma integral para la administración de sus campañas de cobranzas." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Mornese" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/mornese/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-mornese.jpg`}
      />
      <meta property="og:description" content="Mira como Mornese, Contact Center con operaciones globales, usa Beex como plataforma integral para la administración de sus campañas de cobranzas." />
    </Helmet>
    <StructurePageMornese location={location} />
  </div>
)

export default IndexPage
